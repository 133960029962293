import React from 'react';
import './App.css';
import background from './images/background2.jpg'

function App() {
  return (
    <div className="App" >
      <header className="App__background" style={{backgroundImage:"url(" + background + ")"}}>
          <h2 className="App__header">AVENDIA</h2>
      </header>
    </div>
  );
}

export default App;
